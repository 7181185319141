<template>
  <div>
    <topBarY />
    <div class="bg">
      <img src="../../assets/common/pic/bgs.jpg" alt="">
    </div>
    <div class="switchBar">
      <div class="tab contact">云龙公园无线视频监控传输项目</div>
    </div>
    <div class="line"></div>
    <div class="switchContiner">
      <div class="continer contactContiner">
        <div class="txt">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;云龙公园无线监控传输项目采用新诺网络科技推荐的双频无线网桥，
          针对数字化景区各种应用需求推出了领先的无线网络传输解决方案，
          满足各种应用在网络罕台上高效稳定地远行。
          现场工程师与当地SI针对公园做了更为细致和全面的现场勤察，
          结合客户实际需求提出整体解决方案。本方案总计36个监控点，
          最远监控点到控制中心距离为3 公里，设计使用无线网桥44个。<br>
          优势与特点：<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1、采用无线网络传输免除布线的麻烦，不需要破坏路面，建筑物。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2、搭建无线网绪可以实现快速安装，大大降低人工成本，并节约时间成本。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3、具有灵活的扩展性。纵快环晚再复杂多变，无线网络还可以陆时轻松改变和挪移局部的网络系统，
          这个特点尤其适合公园景区内监控点的增减和换位。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4、低成本，无线网络是传统有线网络成本的1/4。在景区内，即使沟埋线或架线可以实施，
          但距离越远成本就增加非常高，主要是施工和线领的成本。而无线网络在20公里以内的成本变化很小。<br>
        </div>
        <div>

        </div>
      </div>
    </div>
    <baseBar />
  </div>
</template>

<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
  name: 'caseThree',
  components: {
    topBarY,
    baseBar
  },
  created() {
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });
  }
}
</script>

<style scoped>
.bg {
  position: relative;
  width: 1920px;
  height: 480px;
  overflow: hidden;
}

.bg img {
  position: absolute;
  top: -100px;
  width: 1920px;
}

.switchBar {
  width: 1920px;
  height: 70px;
  background-color: #fff;
  text-align: center;
}

.switchBar .tab {
  display: inline-block;
  margin-right: 30px;
  margin-top: 15px;
  border-radius: 5px;
  width: 260px;
  height: 40px;
  background-color: #c3a35d;
  color: #f5f5f5;
  font-family: 'Microsoft Yahei';
  font-size: 16px;
  line-height: 40px;
}

.line {
  margin: 0 auto;
  width: 1400px;
  height: 1px;
  background-color: #c3a35d;
}

.switchContiner {
  width: 1920px;
  padding: 20px;
  background-color: #f7f7f7;
}

/* 关于宁讯 */
.switchContiner .contactContiner {
  margin: 0 auto;
  padding: 40px;
  padding-left: 300px;
  padding-right: 300px;
  width: 1400px;
  height: 460px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .contactContiner .txt {
  font-size: 18px;
  line-height: 34px;
}
</style>
